import { Divider as MuiDivider, MenuItem } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { formatVehicleType } from '@superdispatch/sdk';
import styled from 'styled-components';

const Divider = styled(MuiDivider)`
  margin: 8px 0;
`;

const PASSENGER_VEHICLES = [
  'sedan',
  '2_door_coupe',
  'suv',
  'pickup',
  '4_door_pickup',
  'van',
];
const COMMERCIAL_VEHICLES = [
  'truck_daycab',
  'truck_sleeper',
  'heavy_machinery',
  'freight',
];
const RECREATIONAL_VEHICLES = ['atv', 'rv', 'motorcycle', 'boat'];
const TRAILERS = [
  'trailer_bumper_pull',
  'trailer_gooseneck',
  'trailer_5th_wheel',
];
const SPECIALIZED_VEHICLES = ['livestock'];
const OTHER_VEHICLES = ['other'];

function vehicleTypeGroups() {
  return [
    PASSENGER_VEHICLES,
    COMMERCIAL_VEHICLES,
    RECREATIONAL_VEHICLES,
    TRAILERS,
    SPECIALIZED_VEHICLES,
    OTHER_VEHICLES,
  ];
}

export function getVehicleTypeOptions() {
  const groups = vehicleTypeGroups();

  return groups.reduce<JSX.Element[]>(
    (acc, types, index) => [
      ...acc,
      ...types.map((type) => (
        <MenuItem key={type} value={type}>
          {formatVehicleType(type)}
        </MenuItem>
      )),
      ...(index < groups.length - 1 && types.length
        ? [<Divider key={`${index}-divider`} />]
        : []),
    ],
    [],
  );
}

export function VehicleTypeSelect({
  name,
  disabled,
}: {
  name: string;
  disabled: boolean;
}) {
  return (
    <FormikTextField
      select={true}
      name={name}
      disabled={disabled}
      fullWidth={true}
      inputProps={{ 'aria-label': 'vehicle type', 'max-length': 100 }}
    >
      {getVehicleTypeOptions()}
    </FormikTextField>
  );
}
