import { Box, Link, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { ColorDynamic, InfoTooltip, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { OrderFormValues } from 'orders/core/form/OrderForm';
import { FunctionComponent, memo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { CDLinkPreview } from './CDLinkPreview';
import { PaperBox } from './PaperBox';

interface InstructionsProps {
  instructions: OrderFormValues['instructions'];
  loadboard_instructions: OrderFormValues['loadboard_instructions'];
}

export const Instructions: FunctionComponent<InstructionsProps> = memo(
  ({ instructions, loadboard_instructions }) => {
    const { user } = useUserState();

    return (
      <PaperBox title="Instructions">
        {user?.shipper.shipper_type === 'BROKER' &&
          (user.order_form_settings.is_loadboard_instructions_visible ||
            !!loadboard_instructions) && (
            <Box py={1}>
              <Stack space="xxsmall">
                <FormikTextField
                  name="loadboard_instructions"
                  label={
                    <Box display="flex" justifyContent="space-between">
                      <InfoTooltip
                        title={
                          'Visible inside the Super Loadboard. \nCD Post includes link to your load on Super Loadboard.'
                        }
                        TextProps={{
                          color: 'textPrimary',
                        }}
                      >
                        Loadboard Instructions
                      </InfoTooltip>
                      <TextBox color="secondary">
                        {`${loadboard_instructions?.length || 0} of 500`}
                      </TextBox>
                    </Box>
                  }
                  inputProps={{
                    maxLength: 500,
                  }}
                  multiline={true}
                  fullWidth={true}
                  rows={2}
                  helperText="Only 30 characters visible on external load boards."
                />

                <CDLinkPreview instructions={loadboard_instructions || ''} />
              </Stack>
            </Box>
          )}

        {(user?.order_form_settings.is_instructions_visible ||
          instructions) && (
          <Box py={1}>
            <FormikTextField
              name="instructions"
              label={
                <Box display="flex" justifyContent="space-between">
                  <InfoTooltip
                    title="Visible to carrier before and after accepting the load offer"
                    TextProps={{
                      color: 'textPrimary',
                    }}
                  >
                    Order Instructions
                  </InfoTooltip>

                  <TextBox color="secondary">
                    {`${instructions?.length || 0} of 10000`}
                  </TextBox>
                </Box>
              }
              inputProps={{
                maxLength: 10000,
              }}
              multiline={true}
              fullWidth={true}
              rows={5}
            />
          </Box>
        )}

        {user?.shipper.shipper_type === 'BROKER' && (
          <Box py={1}>
            <Typography variant="body2" style={{ color: ColorDynamic.Dark300 }}>
              <span>By posting this load, you are agreeing to standard </span>

              <Link
                href="https://www.superdispatch.com/terms-of-service#tab-id-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                waiver
              </Link>

              <span>.</span>
            </Typography>
          </Box>
        )}
      </PaperBox>
    );
  },
);

Instructions.displayName = 'Instructions';
